@import "assets/styles/colors.scss";
@import "assets/styles/variables.scss";

.footer {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.socialMediaContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
  gap: 25px;
  color: $secondary-background;
}

.internalLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 38px;
  list-style-type: none;
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $secondary-background;
  text-decoration: none;
}

@media (max-width: $mobile-breakpoint) {
  .links {
    flex-wrap: wrap;
  }
}
