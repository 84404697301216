@import "assets/styles/_colors.scss";

.container {
  display: grid;
  justify-content: center;
  row-gap: 30px;
  background: $primary-background;
  padding: 12px 24px;
  margin-top: 20px;

  min-height: 50vh;
  overflow-y: auto;
}

.noResults {
  color: white;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}
