@import "assets/styles/variables.scss";

.logo {
  width: 172.67px;
  height: 140px;
}

@media (max-width: $mobile-breakpoint) {
  .logo {
    width: 172.67px;
    height: 140px;
  }
}
