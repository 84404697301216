@import "assets/styles/variables.scss";
@import "assets/styles/colors.scss";

.container {
  display: grid;
  justify-content: center;
}

.wrapper {
  max-width: 600px;
}

.titleContainer {
  display: grid;
  justify-content: center;
  justify-items: center;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 52px auto;
    text-align: center;
    color: $primary;
  }
}
