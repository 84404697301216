@import "assets/styles/_colors.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: black;
}

.image {
  filter: blur(0.5px);
  max-width: 80vw;
  object-fit: contain;
}
