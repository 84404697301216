.menuItem {
  padding: 20px;
  display: flex;
  place-items: center;
  gap: 10px;
  // color: aliceblue;
  color: inherit;
  background: transparent;

  &:hover {
    background-color: #1890ff;
    color: aliceblue;
  }
}

.layout {
  min-height: 100dvh;
}
