@import "assets/styles/_colors.scss";

.pageContainer {
  position: relative;
  display: grid;
  grid-template-rows: 50vh 50vh;
  row-gap: 0;
  overflow-y: hidden;
}

.googleMapsContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 50vh;
  box-sizing: border-box;
  width: 100%;
}

.locationsContainer {
  grid-row-start: 2;
  overflow-y: auto;
  box-sizing: border-box;
}

.shareButton {
  background-color: $secondary-background;
  border: 1px solid $secondary-background;
  height: 40px;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  padding: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 8px;

  color: $white;
}

.backButton {
  position: fixed;
  z-index: 100;
  width: 85px;
  height: 32px;
  left: 24px;
  top: 24px;
  display: flex;
  gap: 8px;

  justify-content: center;
  align-items: center;

  background: $secondary-background;

  border: 1px solid $secondary-background;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}

.backButtonText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  text-align: center;

  color: $white;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
}
