@import "assets/styles/_colors.scss";
@import "assets/styles/variables.scss";

.pageContainer {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 0;
  overflow-y: hidden;
  background: $primary-background;

  height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  @media (min-width: $tablet-breakpoint) {
    padding: 6vw;
    height: 100vh;
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
  }
}

.betaContainer {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    z-index: 1;
    background: rgba($primary-background, 0.5);

    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: $neutral-4;
    margin-top: 5px;

    @media (min-width: $tablet-breakpoint) {
        padding: 20px 40px;
    }
}

.googleMapsContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 50dvh;
  width: 100%;

  @media (min-width: $tablet-breakpoint) {
    position: relative;
  }
}

.locationsContainer {
  grid-row-start: 2;
  overflow-y: auto;

  height: 50dvh;

  @media (min-width: $tablet-breakpoint) {
    grid-row-start: unset;
    height: calc(100vh - 12vw);
    max-height: unset;
  }
}

.googleMapsContainer {
  @media (min-width: $tablet-breakpoint) {
    height: calc(100vh - 12vw);
    width: 100%;
  }
}
