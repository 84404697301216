@import "assets/styles/variables.scss";
@import "assets/styles/colors.scss";

.layout {
  background-color: $primary-background;
  min-height: 99.9vh;
  display: grid;
  align-content: space-between;
  padding: 35px 60px 30px 60px;
}

.logoContainer {
  display: grid;
  justify-content: center;
}

.logoBetaText {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: $neutral-4;
    margin-top: 5px;
}

@media (max-width: $mobile-breakpoint) {
  .layout {
    padding: 71px 20px 30px 20px;
  }
}
