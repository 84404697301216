//Colors
$primary: #ffffff;
$secondary-45: rgba(0, 0, 0, 0.45);
$neutral-4: #f0f0f0;
$white: #ffffff;
$neutral-5: #d9d9d9;
$neutral-10: #262626;
$success: #52c41a;

$primary-background: #69933a;
$secondary-background: #095105;
$light-background: #bfd6a3;

$antd-outline: #4096ff;
$antd-error: #ff4d4f;
