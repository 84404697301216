@import "assets/styles/colors.scss";
@import "assets/styles/variables.scss";

.form {
  display: grid;
  row-gap: 24px;
  max-width: 500px;
  margin: 0 auto;
}

.inputField {
  width: 100%;
}

.errorMessage {
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
  padding-left: 8px;
  color: $antd-error;
}

.submitButton {
  margin-top: 5vh;
  margin-bottom: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6.4px 15px;
  gap: 10px;

  width: 100%;
  height: 40px;

  background: $secondary-background;
  border: 1px solid $secondary-background;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  cursor: pointer;

  color: #ffffff;
}

.select {
  width: 100%;

  .ant-select-selector {
    border-radius: 2px;
    padding: 8px 12px;
    border: 1px solid $neutral-5;
  }

  div.ant-select-selector {
    border-radius: 2px;
    padding: 8px 12px;
    border: 1px solid $neutral-5;
  }
}

.select.ant-select-selector {
  border-radius: 2px;
  padding: 8px 12px;
  border: 1px solid $neutral-5;
}

@media (max-width: $mobile-breakpoint) {
}
