@import "assets/styles/_colors.scss";
@import "assets/styles/variables.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: column;
  padding: 24px;
  background-color: $white;
  height: 50dvh;

  @media (min-width: $tablet-breakpoint) {
    margin-left: 24px;
    height: unset;
  }
}

.toolsContainer {
  display: grid;
  row-gap: 8px;
}

.primaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  gap: 8px;

  height: 40px;

  background: $primary-background;

  border: 1px solid $primary-background;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $white;
  cursor: pointer;
}

.shareButtonContainer {
  display: flex;

  gap: 8px;
}

.shareButton {
  background: $secondary-background;
  border: 1px solid $secondary-background;

  color: $primary;
}

.secondaryButton {
  cursor: pointer;

  height: 40px;
  width: 100%;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  gap: 10px;
}

.sharedIcon {
  width: 14px;
  height: 14px;
}

.directionButton {
  background: white;
  border: 1px solid $primary-background;
  color: $primary-background;
}
