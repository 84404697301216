@import "assets/styles/_colors.scss";
@import "assets/styles/variables.scss";

.container {
  position: absolute;
  z-index: 100;
  margin: 24px;
  padding: 16px 24px;

  display: grid;
  align-content: center;
  justify-items: center;

  width: calc(100% - 48px);

  row-gap: 4px;

  background: $primary-background;

  @media (min-width: $tablet-breakpoint) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
}

.description {
  font-weight: 400;
  font-size: 13px;
  line-height: 131%;
  color: $primary;
  text-align: center;
}

.logo {
  width: 44.85px;
  height: 57.5px;
}

.closeButton {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  background: transparent;
  z-index: 10;

  display: grid;
  place-items: center;
}
