@import "assets/styles/colors.scss";

.layout {
  display: grid;
  justify-content: center;
  row-gap: 20px;
}

.tableWrapper {
  min-width: 1100px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  button {
    padding: 10px !important;
    height: unset !important;
  }
}

.errorMessage {
  color: $antd-error;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
}
