@import "assets/styles/_colors.scss";

.container {
  display: grid;
  row-gap: 25px;
  align-content: flex-start;
  padding: 24px;
  background: $white;
  border: 1px solid $neutral-4;
  cursor: pointer;
  border-radius: 20px;
}

.recommended {
  outline: 10px solid #095105;
}

.isRecommendedLogo {
  background-color: #095105;
  border-radius: 999px;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.isRecommendedLogo > img {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.name {
  font-weight: 600;
  font-size: 25px;
  line-height: 24px;
  flex: 1 1 auto;
  text-transform: uppercase;
}

.nameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.priceContainer {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: $success;
}

.detailPriceContainer {
  line-height: 35px;
}

.addressContainer {

}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: black;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagsContainer {
  font-weight: 600;
}

.website {
  font-style: normal;
  font-weight: 400;
  font-size: 12.7029px;
  line-height: 21px;
  /* or 169% */

  color: rgba(0, 0, 0, 0.45);

  cursor: pointer;
}

.isActive {
  border-color: $neutral-10;
}

.closeButton {
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-left: 5px;

  background: $primary-background;
  border: 1px solid $secondary-background;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}

.detailViewContainer {
  cursor: unset;
  border-radius: unset;
  outline: unset;

  padding: unset;
  padding-bottom: 8px;

  border: unset;
}

.detailViewWrapper {}
