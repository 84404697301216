.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;

  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-content: center;
  place-items: center;

  z-index: 100;
}
