@import "./variables.scss";
@import "./fonts.scss";
@import "./colors.scss";

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body {
  height: 100dvh;
}

.ant-spin-dot-item {
  background-color: $secondary-background !important;
}
