@import "assets/styles/_colors.scss";

.input {
  height: 40px;
  border-radius: 0;
  border: 1px solid $neutral-5;
  border-radius: 2px;
  padding: 8px 12px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  width: 100%;

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
}

.showLocationDetector {
  padding-right: 45px;
}

.inputWrapper {
  position: relative;
}

.iconWrapper {
  position: absolute;
  right: 15px;
  top: 10px;
  background: transparent;
  z-index: 10;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
}
